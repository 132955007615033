/* login.css */

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #fff;
}

.wrapper {
    border-radius: 10px;
    padding: 20px 40px;
    width: 500px;
    background: #fbe5d67a;
    backdrop-filter: blur(30px);
    color: #000;
    margin: 10px;
}

.wrapper img {
    width: 100px;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 30px auto;
}

.wrapper .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    /* background: transparent; */
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 17px;
    color: #000;
    padding: 20px 45px 20px 20px;
    border: 1px solid #f2aa84;
}

.input-box input::placeholder {
    color: #000;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
}

.wrapper button {
    width: 100%;
    height: 45px;
    background: #f2aa84;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(223, 122, 21, 0.1);
    cursor: pointer;
    font-size: 17px;
    color: #000;
    transition: background-color 0.3s, color 0.3s;
}

.wrapper button:disabled {
    background: #cccccc;
    cursor: not-allowed;
    color: #666666;
}

.wrapper button:hover:not(:disabled) {
    color: #e87b41;
    background-color: #fff;
    border: 1px solid #f2aa84;
}

.input-box input, .wrapper .remember-forget, .wrapper button {
    font-size: 17px;
}

.error-message {
    color: red;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
}

@media screen and (max-width:450px) {
    .wrapper {
        padding: 30px 15px;
    }

    .wrapper .input-box {
        width: 100%;
        height: 50px;
        margin: 30px 0;
    }

    .input-box input, .wrapper button {
        font-size: 16px;
    }

    .wrapper img {
        width: 100px;
    }
}
