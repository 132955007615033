*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
body {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lato", sans-serif;
}
.leaflet-popup-content p{
  margin: 5px 0 !important;
}