.user-container {
    height: 79vh;
    margin: 10px;
    padding: 20px 10px;
    font-family: Arial, sans-serif;
  }
  
  .search-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 60%;
  }
  
  .search-container input[type="text"] {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .search-container button {
    background-color: #007bff; /* Bootstrap blue color */
    color: white;
    border: none;
    padding: 10px;
    margin-left: 5px;
    border-radius: 4px;
    cursor: pointer;
  }

  .usercard-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;

  }
  .user-container span{
    color: #0c5b84;
   
  }
  .user-container p{
    margin: 10px 0;
  }
  .user-container h3{
    margin-top: 15px;
  }
  .user-container h4{
    color: #f2aa84;
    font-size: 18px;
  }
  .selected-card h3{
    color: #f2aa84;
    text-align: center;
    
  }
  .usercard {
    background-color: #FDF3EE;
    border: 1px solid #ccc;
    padding: 25px;
    cursor: pointer;
    transition: transform 0.2s ease;
    border-radius: 8px;
  }
  
  .usercard:hover {
    transform: translateY(-5px);
  }
  
  .selected-user {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .selected-card {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    width: 100%;
    position: relative;
    margin: 10px;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 25px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: black;
  }
  
  /* Responsive adjustments */
  /* @media (max-width: 868px) {
    .usercard-container {
      grid-template-columns: repeat(auto-fit, minmax(150px));
   
    }
  } */
  @media (max-width: 900px){
    .user-container{
        margin:0;
    }
    .search-container{
      width: 100%;
    }
  }