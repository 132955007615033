.issue-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px;
    padding: 20px;
  }
  .no-issues-message p{
    text-align: center;
    font-size: 17px;
    color: red;
  }
  .issue-section {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .issue-section h2 {
    margin-bottom: 18px;
    font-size: 17px;
    color: #f2aa84;
    text-transform: uppercase;
  }
  
  .issue-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .issue-cards span{
    color: #007bff;
    font-size: 17px;
   
  }
  .issue-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    flex: 1 1 calc(33% - 20px); 
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .issue-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .issue-body {
    margin-top: 10px;
  }
  .issue-body p{
    margin: 10px 0;
  }
  .issue-footer {
    margin-top: 10px;
    font-size: 0.9em;
    color: #555;
  }
  
  /* .issue-cards button {
    cursor: pointer;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    
  }
  
  .issue-cards  button:hover {
    background-color: #0056b3;
  } */
  
  .issue-card {
    position: relative; 
    /* border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px; */
  }
  
  .issue-body {
    padding: 10px;
  }
  
  .issue-body button {
    cursor: pointer;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    position: absolute; 
    bottom: 10px; 
    right: 20px; 
    
  }
  
  .issue-body button:hover {
    background-color: #0056b3;
  }
  
   .alert-modal {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    margin-top: 30px;
  }
  
  .alert-modal-content {
    display: flex;
    align-items: center;
    max-width: 80%;
    max-height: 80%;
  }
  
  .alert-modal-content img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    
  }
  .issue-header span{
    color: #f2aa84;
  }
 
  .issue-header .icon {
    cursor: pointer;
    width: 24px; 
    height: 24px; 
    
  }
  /* .issue-body{
    background-color: #FDF3EE;
  } */
  .issue-body p strong{
    color: #0c5b84;
    margin-right: 5px;
    font-size: 15px;
  }
  .issue-body p{
font-size: 14px;
  }
  .issue-body h3{
    font-size: 16px;
    margin: 5px 0;
    color: #f2aa84;
  }
  .filter-section {
    display: flex;
    flex-wrap: wrap; 
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 15px;
  }
  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .modal-image {
    max-width: 90%;
    max-height: 70%;
    border: 2px solid white; 
  }
  .filter-field {
    margin-right: 15px;
    margin-bottom: 10px; 
    flex: 1;
  }
  
  .filter-field label {
    display: block;
    margin-bottom: 5px;
    color: #f2aa84;
  }
  
  .filter-field input,
  .filter-field select {
    width: 220px; 
    padding: 5px;
    border: none; 
    border-bottom: 1px solid #ccc; 
    border-radius: 0; 
    box-shadow: none; 
    background-color: transparent; 
  }
  
  .filter-field input:focus,
  .filter-field select:focus {
    border-bottom-color: #007bff; 
    outline: none;
  }
  
  .filter-section button {
    align-self: center; 
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .filter-section button:hover {
    background-color: #0056b3;
  }
  .alert-modal p{
    color: #fff;
    text-align: center;
    margin: 10px;
    font-size: 18px;
}
  .alert-modal strong{
    color: #f2aa84;
    margin-right: 5px;
  }
  .close-popup{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #edebeb;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  .close-popup p{
    margin: 10px 0 20px;
  }
  .close-popup .buttons{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
  }
 .close-popup button{
  padding: 8px 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: #fff;
 }
 .issue-card {
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-container {
  display: flex;
  gap: 8px;
  margin-top: auto; /* Pushes buttons to the bottom */
  justify-content: center;
}

.case-button {
  background-color: #f2aa84; 
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  /* font-weight: bold; */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.case-button:hover {
  background-color: #f2aa84;
}

.case-button:active {
  background-color: #f2aa84;
}

  @media (max-width: 900px){
    .issue-page{
      margin: 0px;
      padding: 10px;
    }
    .issue-cards{
      flex-direction: column;
    }
    .alert-modal-content img {
      height: auto;
    }
    .filter-field input,
  .filter-field select{
    width: 90%;
    
  }
  .filter-section{
    margin: 5px;
  }
  .filter-section {
    flex-direction: column; 
  }
  
  .filter-field {
    margin-right: 0;
    margin-bottom: 20px; 
  }
  .filter-section button{
    align-self: end;
  }
  .issue-page .close-popup{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 90%;
  }
  }