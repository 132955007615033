.issue-details {
  width: 90%; 
  max-width: 1200px;
  margin: 8% auto; 
  padding: 30px;
  background: linear-gradient(to bottom right, #f9f9f9, #e5e5e5);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.issue-details h1 {
  text-align: center;
  color: #0c5b84;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.issuedetail-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  align-items: center;
}

.issue-content p strong {
  color: #0c5b84;
  margin-right: 5px;
}

.issue-content {
  display: flex;
  flex-direction: column;
  margin-right: 20px; 
}

.issue-details p {
  margin: 0.5rem 0;
  font-size: 17px; 
  color: #555;
  font-weight: normal;
}

.issue-image {
  display: block;
  max-width: 100%; 
  height: auto;
  border-radius: 10px;
  margin: 1rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.button-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
}

.back-button, .map-button {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button {
  background-color: #007bff; 
}

.map-button {
  background-color: #28a745; 
}

.back-button:hover {
  background-color: #0056b3;
}

.map-button:hover {
  background-color: #218838;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content h2 {
  margin: 0 0 20px;
  font-size: 17px;
}

/* Full-size photo */
.full-size-photo {
  width: 80%;  
  height: 350px; 
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.photo-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.nav-icon {
  cursor: pointer;
  font-size: 2.5rem; 
  margin: 0 40px; 
  color: #fff;
  transition: color 0.2s;
}

.nav-icon:hover {
  color: #007bff;
}


/* Photo Modal Styles */
.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.issuemodal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
}

.full-size-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.photo-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-icon {
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease;
}

.nav-icon:hover {
  color: #007bff; /* Change color on hover */
}

.photo-modal button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.photo-modal  button:hover {
  background-color: #d93636;
}


/* History Modal */
.history-card h2{
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
  color: #f2aa84;
}

.case-history-list {
  margin-top: 15px;
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 8px;
  max-height: 60vh; 
  overflow-y: auto;
}

.history-item {
  padding: 12px;
  margin-bottom: 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;

}

.history-item p {
  margin: 6px 0;
  font-size: 16px;
}

.history-item strong {
  color: #f2aa84;
  font-weight: normal;
  margin-right: 5px;
  font-size: 17px;
}

.history-photo {
  width: 100%;
  max-width: 160px; 
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Close button */
.history-modal button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.history-modal button:hover {
  background-color: #0056b3;
}
.issue-map h3{
  text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    /* text-transform: uppercase; */
    font-weight: bold;
    letter-spacing: 1px;
    color:#0056b3 ;
}
/* Responsive styling */
@media (max-width: 600px) {
  .issue-details {
     width: 95%; 
  }
  .issuedetail-content {
     flex-direction: column; 
  }

  .history-modal {
     max-width: 95%;
  }

  .full-size-photo {
     width: 90%;
     height: auto; /* Auto-height for responsive image */
  }

  .nav-icon {
     font-size: 1.8rem; /* Reduced icon size */
     margin: 0 20px;
  }
  .history-item {
    display: block;
  }
  .history-item img{
    margin: auto;
    display: block;
    margin-top: 23px;
  }
  .photo-navigation{
    justify-content: center;
  }
  .issuemodal-content{
    max-width: 90%;
    padding: 63px 20px 10px 20px;
  }
  .nav-icon {
    font-size: 2rem;
    margin: 0 9px;
}
.full-size-photo {
  width:75%;
  height: auto;
}
.issue-details{
  margin: 27% auto;
}
}
