.live-location-map {
    height: 70vh;
    width: 100%;
   
  }
  
  .leaflet-container {
    height: 100%;
    width: 100%;
    
  }
  .marker-container {
    background-color: rgba(255, 255, 255, 0.9); 
    border-radius: 8px; 
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); 
    position: absolute; 
    top: 100px; 
   font-size: 14px;
    right: 20px; 
    z-index: 1000; 
  }
  

  .marker-legends {
    display: flex; 
    flex-direction: column;
    align-items: center;
    /* margin-right: 10px; */
  }
  
  .legend-items {
    display: flex;
    align-items: center; 
    margin-bottom: 8px;
  }
 
  
  /* @media (max-width: 900px){
    .live-location-map{
      margin: 10px;
    }
  } */