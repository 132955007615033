.map-page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px;
  margin: 10px; 
  
}
.map-page .map-container{
width: 100%;

}
.map-icon-details {
 border: 1px solid #ccc;
 background: #f1f1f1;
 padding: 15px 50px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  align-self: center;
}

.marker-legend {
  display: flex;
  flex-direction:row;
 
  gap: 25px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Style for the marker images */
.pin-image {
  width: 20px; /* Adjust as necessary */
  height: 20px; /* Adjust as necessary */
}

/* Circle icons for read/unread */
.circle-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: green !important;
}

.orange {
  background-color: orange;
}

.map-page h3{
  margin: 20px 0;
}
/* .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  bottom: 27px !important;
    left: -124px !important;
} */
.image-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.image-popup-content {
  position: relative;
  background: #fff;
  padding: 50px 100px;
  border-radius: 5px;
  text-align: center;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.image-popup-content img {
  max-width: 100%;
  max-height: 80vh;
}


.marker-id {
  position: absolute;
  top: 10px;
  left: 10px;
 color: #000;
  padding: 5px 40px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;

}
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

.search-box input[type='text'] {
  width: 250px; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}
.search-box button {
  background-color: #333;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px; 
}


.search-icon {
  margin-left: 10px;
  font-size: 20px;
  color: #888;
  cursor: pointer;
}
.about-region {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  gap: 8%
}
.about-region span{
  color: #f2aa84;
  font-weight: bold;
  padding-right: 5px;
}
.content-container {
  display: flex;
  width: 100%;
  height: 60vh;
}

.map-container {
  width: 60%;
  height: 60vh; 
}

.marker-list-container {
  width: 40%;
  overflow-y: auto;
}

.marker-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
}

.marker-list {
  border: 1px solid #ccc;
}

.marker-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.marker-row:hover {
  background-color: #f9f9f9;
}
/* mappage.css */

.marker-list-container {
 
  overflow-y: auto;
}

.map-container {
  height: 60vh;
  overflow: hidden;
}
.map-page {
  padding: 15px 12px;
}

.search-box {
  margin-bottom: 20px;
}

.trip-info {
  margin-bottom: 20px;
}

.map-and-list-container {
  display: flex;
  height: 60vh;
  width: 100%;
}

.map-container {
  flex: 1;

  margin-right: 20px;
}

.marker-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: auto;
}

.marker-list-header,
.marker-list-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.marker-list-header {
  font-weight: bold;
  background-color: #f0f0f0;
}

.marker-list-column {
  flex: 1;
  text-align: left;
}

.marker-list-row {
  cursor: pointer;
}

.marker-list-row:hover {
  background-color: #f9f9f9;
}

.error {
  color: red;
}

.leaflet-control-attribution a{
  display: none;
}
.image-popup .close-button{
  top: 14px;
    right: 17px;
   
    font-size: 15px;
    font-weight: 800;
}
@media (max-width: 900px) {
  .map-page {
    margin: 93px 10px 10px; 
  }

  .map-container {
    width: 100%;
    height: auto;
  }
  .map-page h3{
    margin: 10px 0;
  }

  .marker-list-container {
    width: 100%;
    overflow-y: auto;
    height: 50vh; 
    margin: 20px 0;
  }
  .about-region{
    flex-direction: column;
    gap: 5px;
  }
  .map-page .content-container{
    flex-direction: column-reverse;
    height: auto;
  }
  .map-and-list-container {
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
  }
  .marker-list{
    width: 100%;
    margin: 20px 0;
  }
}
.tripmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.tripmodal-content {
  position: relative; 
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  max-width: 350px;
  width: 100%;
  text-align: center;
}
.tripmodal-content h4{
margin-bottom: 5px;
color: #000;
}
.tripclose-modal {
  position: absolute;
  top: 5px; 
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #000;
}
