.trip-page {
  padding: 10px;
  margin:10px;
}
.trip-page h3{
  font-size: 20px;
  margin: 22px 0;
}

.trip-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.trip-card {
  background: #f2f2f2;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;

}

.region-page{
  height: 83vh;
}

.trip-card h4,
.trip-card p {
  margin: 20px 0;
}
.trip-card p{
  font-size: 16px !important;
}
.trip-card h4{
  color: #f2aa84;
  font-size: 19px;
}
.trip-card span{
  color: #f2aa84;
  font-size: 17px;
  margin-right: 5px;
}
.photo-count {
  color: blue !important;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
  position: relative;
  text-align: center;
  width: 100%;

}

.modal-close-button {
  position: fixed;
  top: 20px;
  right: 0;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  font-size: 16px;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  background: none;
  border: none;
  gap: 3px;
 
}

.close {
  font-size: 19px;
  align-items: center;
  margin: 0 6px 7px;
  
}

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carousel-image {
  max-width: 80%;
  max-height: 80vh;
  width: auto;
  height: auto;
  border-radius: 8px;
  margin: auto;
}

.carousel-button {
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  padding: 12px 14px;
  border-radius: 50%;
  margin: 0 20px;
  background: transparent;
  border: 2px solid #ccc;
}
.carousel-button.left {
  position: absolute;
  left: 10px;
}

.carousel-button.right {
  position: absolute;
  right: 10px;
}

/* .photo-collage-page {
  padding: 20px;
  margin: 100px 10px 0 18%;
} */
.pin-images-page{
  margin: 100px 10px 0 18%;
  text-align: center;
  height: 78.5vh;
}
.pin-images-page h2,.pin-images-page p{
  margin: 10px;
}
.photo-collage {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.collage-photo {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  cursor: pointer;
}
.modal h2{
 color: #fff;
 margin: 5px 0;
}
.modal p{
  color: #fff;
}
.creation-date{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
}
.creation-date span{
  color: #f2aa84;
}
.line-intersect{
  width: 4px;
  height: 22px;
  background: #f2aa84;
}
.trip-info{
  display: flex;
    margin: 20px 0;
    gap: 30px;
}

.trip-info span{
  color: #f2aa84;
}

@media (max-width: 900px) {
  .photo-collage-page {
    margin: 10px;
    padding: 0;
  }
  .trip-container {
    display: grid;
    gap: 20px;
    grid-template-columns: auto;
}
  .trip-page {
    padding:  0;
    margin: 115px 20px;
  }
  .carousel-button{
    padding: 5px 7px;
  }
  .carousel-button.left{
    left: -15px;
  }
  .carousel-button.right{
    right: -15px;
  }
  .close-button{
    top: 19px;
    right: -3px;
  }
  .pin-images-page{
    margin:100px  10px;
  }
  .region-page{
    height: auto;
  }
  .trip-info{
    display:flex;
    flex-direction: column;
    gap: 5px;
  }
  .modal-content{
    width: 100%;
  }
  .creation-date {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
    flex-direction: column;
}
.line-intersect{
  display: none;
}
.modal-close-button{
  top: 30px;
}
}

