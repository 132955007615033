.cards{
  margin: 10px 18px;
    height: 75vh;
    cursor: pointer;
    padding-top: 20px;
}
.cards h3{
margin: 15px 0;
}
  .card-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 10px;
  }
  
  .card {
    background: #f2f2f2;
    color: #000;
    padding:40px 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .card h4 {
    margin: 0 0 10px 0;
    font-size: 19px;
    color: #f2aa84;
  }
  
  .card p {
    font-size: 17px;
    margin: 0;
  }
  
@media screen and (max-width: 900px){
    .cards{
        margin: 0 13px 0;
        height: auto;
    }
    .card-container {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        margin: 10px;
      }
}