.marker-location {
    padding:  0;
    margin:9% 20px;
}

.marker-location h3 {
    color: #000;
    margin: 0px 0 20px;
    text-align: center;
    font-size: 20px;
}
.markerform-details{
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 600;
}
.marker-location form {
    width: 90%;
    height: auto;
    border: 1px solid #f2aa84;
    padding: 10px 30px;
    margin: auto;
    border-radius: 10px;
    background-color: #fbe5d6;
}

.marker-location input[type='text'] {
    border: none;
    width: 90%;
    padding: 10px;
    font-size: 17px;
}

.location,
.choose-file {
    border: none;
    width: 20%;
    padding: 10px;
    font-size: 17px;
    background-color: #f2aa84;
    border-radius: 5px;
    color: #fff;
    margin: 10px;
    cursor: pointer;
    text-align: center;
}

.location:hover,
.choose-file:hover {
    background-color: #f38657;
}

.marker-location span {
    font-size: 14px;
    color: #0c5b84;
}

.marker-location label {
    margin: 30px 0 10px 0;
}

.marker-submit {
    width: 30%;
    background-color: #f2aa84;
    border: none;
    margin: 20px auto;
    height: 40px;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
}

/* .marker-submit:hover {
    background-color: #fff;
    color: #f2aa84;
    border: 2px solid #f2aa84;
} */

.marker-submit:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.webcam-controls button {
    margin: 10px;
    font-size: 16px;
    padding: 10px 20px;
    background-color: #f2aa84;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.webcam-controls button:hover {
    background-color: #f38657;
}
.success-message{
    font-size: 17px;
    margin: 20px 10px;
}
.refresh{
    width: 10%;
    font-size: 17px;
    color: #000;
    border: 1px solid #ccc;
    height: 40px;
    border-radius: 10px;
    margin: 0 10px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .marker-location {
        padding: 0;
        /* margin-left: 0px; */
       
       
    }
    .marker-location {
        padding:  0;
        margin:100px 20px;
    }
    .marker-location form {
        width: 100%;
        padding: 10px;
    }

    .marker-location input[type='text'],
    .marker-location textarea[type='text'] {
        border: none;
        width: 100%;
        padding: 15px;
    }

    .marker-location label {
        margin: 30px 0 10px 0;
    }

    .location {
        width: 80%;
    }
    .choose-file{
        width: 35%;
        padding: 10px 5px !important;
    }
    .marker-submit{
        width: 40%;
    }
    .refresh{
        width: 20%;
        height: 42px;
    }
}