.marker-details {
    margin: 6% auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f1f1f1;
    width: 50%;
  }
  
  .marker-details h3 {
    font-size: 23px;
    color: #000;
    text-align: center;  
    
    margin-top: 30px;
    
  }
  
  .marker-details p {
    margin: 10px 0;

  }
  .marker-details span{
    font-size: 17px;
    color: #0c5b84;
    padding-right: 10px;
    font-weight: 500;
  }
  
  .marker-details img {
    display: block;
    margin: 10px auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 200px;
    height: 200px;
  }
  .detail-refresh{
    width: 14%;
    font-size: 17px;
    color: #000;
    border: 1px solid #ccc;
    height: 40px;
    border-radius: 10px;
    margin: 0 50px 0 10px;
    float: right;
    cursor: pointer;
  }
  
@media screen and (max-width:600px){
  .marker-details{
    width: 95%;
  }
  .detail-refresh{
    width: 44%;
  }
}