@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Great+Vibes&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Kalam:wght@300;400;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');
.home h1{
    padding: 10% 0;
    
    text-align: center;
   letter-spacing: 1px;
   line-height: 70px;
   font-size:45px ;
   color: #0c5b84;
}
.home{
    height: 93vh;
}
@media screen and (max-width: 500px) {
    .home h1{
        padding: 35% 10px;
       font-size:40px ;
       line-height: 60px;
    }
}