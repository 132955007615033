/* Sidebar Styles */
.sidebar {
  width: 60px; 
  background: #f2aa84;
  height: calc(100vh - 85px); 
  position: fixed;
  top: 85px; 
  left: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  /* transition: transform 0.3s ease, width 0.3s ease; */
}

.sidebar.open {
  width: 200px; 
}

.sidebar .menu {
  padding: 10px 5px;
  flex: 1;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  color: #fff;
  font-size: 24px;
  padding: 15px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  position: relative;
}

.menu li span {
  font-size: 17px;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.menu li:hover {
  color: #0c5b84;
}

.menu a {
  text-decoration: none;
}

.active li {
  color: #0c5b84;
}

/* Navbar Styles */
.navbar {
  height: 85px;
  background: #f2aa84;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.logo-toggle {
  display: flex;
  align-items: center;
}

.toggle-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.line{
  width: 25px;
  height: 3px;
  background: #fff;
  margin: 3px 0;
}
.line1 {
  width: 31px; 
  border-radius: 50px;
}

.line2 {
  width: 23px; 
  border-radius: 50px;
}
.line3 {
  width: 13px; 
  border-radius: 50px;
}


.profile {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.dropdown {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
  width: 200px;
}

.dropdown.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
  border-radius: 8px;
}

.user-name {
  font-size: 17px;
  margin-right: 8px;
  color: #000;
}

/* Content Styles */
.content {
  margin-top: 84px;
  margin-bottom: 60px;
  margin-left: 55px;
  transition: margin-left 0.3s ease;
}

.sidebar.open ~ .content {
  margin-left: 200px; 
}

/* Footer Styles */
.footer-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ccc; 
  color: #000; 
  padding: 10px;
  text-align: center;
  z-index: 999;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}
/* 
.sidebar.open ~ .footer-section {
  width: calc(100% - 300px); 
} */

.notification-badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 6px;
  font-size: 10px !important;
  position: absolute;
  top: 4px;
  right: 4px;
}


.sidebar.open .notification-badge {
  top: 16px;
  right: 75px;
}

@media (max-width: 900px) {
  .sidebar {
    transform: translateX(-200%);
    width: 60px; 
  }

  .sidebar.open {
    transform: translateX(0);
    width: 250px; 
    z-index: 9999;
  }

  .content {
    margin-left: 0; 
    margin-top: 80px;
  }

  .sidebar.open ~ .content {
    margin-left: 0;
  }

  .footer-section {
    width: 100%; 
    left: 0;
  }

  .sidebar.open ~ .footer-section {
    width: 100%;
  }
}

