/* .navbar{
    height: 90px;
    background: #f2aa84;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    width: 100%;

}
.navbar img{
    width: 70px;
    height: 70px;
    object-fit: contain;
}
.profile img{
    border-radius: 50px;
    width: 40px;
    height: 40px;
} */
.navbar {
    height: 90px;
    background: #f2aa84;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .navbar img {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
  
  .profile img {
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }
  
